import SearchIcon from "@mui/icons-material/Search";
import { Grid, Typography, InputAdornment, TableContainer, TableHead, TableCell, Paper, Table, TableRow, TableBody, TextField, Checkbox } from "@mui/material";

import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import React, { ChangeEvent } from "react";
import FormModal from "../components/FormModal";
import { FormatDataByTypeCode, evalDisableRules, setPageVariables, parseBoolean, emptyFormObject, getFormLineById, parseIntFromTd, parseTimeDurationFromUser, validateEmail, cloneObject } from "../components/FormScripts";
import LUTModal, { evalDependsOn } from "../components/LUTModal";
import WrappedButton from "../components/WrappedButton";
import { isNullOrUndefined } from "../Util";
import { AssessmentRender } from "./Assessments";
import { FormLine } from "./FormLine";
import SignatureLine from "./SignatureLine";
import MemoControl from "./MemoControl";
import TimeControl from "../components/TimeControl";

interface RenderProps {
	formLine: FormLine;
}
export function FormLineRender(props: RenderProps) {
	const fl = props.formLine;
	const [modified, setModified] = React.useState(false);
	const [val, setVal] = React.useState((fl.lutValue?.value ?? fl.value) as any);
	const [showLutModal, setShowLutModal] = React.useState(false);
	const [formattedVal, setFormattedVal] = React.useState("");
	const [showSubformModal, setShowSubformModal] = React.useState(false);
	const [formModalObj, setFormModalObj] = React.useState(null as any);
	const [disabled, setDisabled] = React.useState(false);
	const [dflChecked, setDflChecked] = React.useState([] as Array<boolean>);

	const [isDrivingFormLine, setIsDrivingFormLine] = React.useState(false);

	// eslint-disable-next-line
	React.useEffect(() => {
		if (!isNullOrUndefined(fl.defaultValue) && (window as any).currentForm.formMode === "ADD") {
			try {
				// eslint-disable-next-line
				var res = "";

				// eslint-disable-next-line
				if (fl.typeCode !== "LABEL") res = eval(fl.defaultValue.toString());
				else res = fl.defaultValue.toString();

				fl.setValue(res);
			} catch (e: any) {
				alert("Failed to eval default value for form line: " + fl.caption + "; " + fl.defaultValue + " exception: " + e.toString());
			}
		}
		setFormattedVal(FormatDataByTypeCode(fl.value, fl.typeCode));
		// eslint-disable-next-line
	}, []);

	//#region misc form line functions
	const checkChange = async () => {
		fl.modified = fl.isModified();
		setVal(fl.lutValue?.value ?? fl.value);
		setModified(fl.modified);
		fl.isDirty = fl.modified;
		setFormattedVal("");

		try {
			// eslint-disable-next-line
			if (fl.onchangeEvent) eval(fl.onchangeEvent);
		} catch (ex: any) {
			alert("Error in onchange event for: " + fl.caption);
			console.error(ex);
		}
		evalDisableRules();
	};

	const handleLutClose = (res: any) => {
		setShowLutModal(false);
		if (isNullOrUndefined(res)) return;
		else if (!res) {
			res = {
				code_field: null,
				display_exp: null,
				prompt_field: null,
			};
		}

		(fl.lutValue as any).value = res.code_field;
		(fl.lutValue as any).description = res.display_exp;
		(fl.lutValue as any).prompt = res.prompt_field;
		checkChange();
	};

	const launchLutModal = () => {
		setShowLutModal(true);
	};

	const handleSubFormClose = (res: any) => {
		if (!res && !window.confirm("Any unsaved changes will be lost, continue?")) {
			return;
		}
		setShowSubformModal(false);
		(window as any).currentForm = (window as any).parentForm;
		setPageVariables((window as any).currentForm);

		if (!res) {
			if (isDrivingFormLine) {
				const idx = fl.sfValue.findIndex((x: any) => x.keyValue === formModalObj.keyValue);
				fl.sfValue[idx].formMode = "NEW";
				dflChecked[idx] = false;
				setDflChecked(dflChecked);
			}

			return;
		}

		fl.subFormHasData = true;

		if (res.formMode === "NEW" || (res.formMode === "ADD" && res.keyValue.toString().toUpperCase() === "NEW")) {
			res.formMode = "ADD";
			res.keyValue = "NEW" + Math.random().toString().substring(2);
		}

		let existing = fl.sfValue.findIndex((x: any) => x.keyValue === res.keyValue);
		if (existing >= 0) {
			fl.sfValue[existing] = res;
		} else {
			fl.sfValue.push(res);
		}

		fl.setValue(fl.sfValue);
		fl.emptyForm = emptyFormObject(cloneObject(fl.sfValue[0]));
		fl.emptyForm.formMode = "NEW";
	};

	fl.setDisabled = setDisabled;
	fl.getDisabled = () => {
		return disabled;
	};
	if (fl.caption === "Tasks/Schedules" && fl.isGroupHeader) {
		fl.isVisible = false;
	} else if (fl.isVisible && parseBoolean(fl.XMLElementName)) {
		fl.isVisible = false;
	}

	if (fl.isSubForm() && isNullOrUndefined(fl.emptyForm)) {
		fl.emptyForm = emptyFormObject(fl.sfValue[0]);
		fl.emptyForm.formMode = "NEW";
		const cf = (window as any).currentForm;

		if (cf.formMode === "ADD" || cf.formMode === "NEW") {
			fl.sfValue = [];
		}

		if (fl.emptyForm.drivingFormLineId) {
			setIsDrivingFormLine(true);
			const dfl = getFormLineById(fl.emptyForm.drivingFormLineId, fl.emptyForm) as any;
			if (dfl) {
				const lutRequest = {
					Code: dfl.lutValue.scrnval_id,
					WhereClause: [] as Array<any>,
					Parameter1: "",
					Parameter2: "" as string,
					Parameter3: "" as string,
					Parameter4: "" as string,
					Parameter5: "" as string,
				};

				evalDependsOn(lutRequest, dfl.lutValue);

				fetch("form/GetLutData", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					cache: "no-cache",
					body: JSON.stringify(lutRequest),
				})
					.then((x) => x.json())
					.then((x) => {
						x = JSON.parse(x) as Array<any>;
						const search = x.map((x: any) => x.code_field.toLowerCase()).join(",");
						fl.sfValue = fl.sfValue.filter((x: any) => x.formMode === "EDIT" && search.includes(getFormLineById(fl.emptyForm.drivingFormLineId, x).lutValue.value?.toLowerCase()));
						x = x.filter((y: any) => isNullOrUndefined(fl.sfValue.find((z: any) => getFormLineById(fl.emptyForm.drivingFormLineId, z).lutValue.value?.toLowerCase() === y.code_field.toLowerCase())));
						const forms = x.map((form: any, idx: number) => {
							const y = emptyFormObject(fl.emptyForm);
							y.formMode = "NEW";
							y.keyValue = "NEW" + Math.random().toString().substring(3);
							const f = getFormLineById(fl.emptyForm.drivingFormLineId, y) as any;
							f.isDirty = true;
							f.lutValue.description = form.display_exp;
							f.lutValue.prompt = form.prompt_field;
							f.lutValue.value = form.code_field;
							f.disableRule = "true";
							f.isDisabled = true;

							return y;
						});

						fl.sfValue = fl.sfValue.concat(forms);
						fl.sfValue.forEach((y: any, idx: number) => (dflChecked[idx] = y.formMode === "EDIT"));
						fl.setValue(fl.sfValue);
						setDflChecked(dflChecked);
					});
			}
		}
	}

	if (!fl.isVisible) {
		if (fl.isGroupHeader) {
			(window as any).currentForm.FormLines.filter((x: any) => x.belongsTo?.toLowerCase() === fl.formLinesId.toLowerCase()).forEach((x: any) => (x.isVisible = 0));
		}
		return <></>;
	}

	if (fl.isGroupHeader)
		return (
			<Grid item xs={12} sx={{ borderBottom: "1px solid #8f8f8f", paddingLeft: 1, paddingTop: 1, paddingBottom: 1, marginBottom: 2 }}>
				<Typography variant="h6">{fl.caption}</Typography>
			</Grid>
		);
	else if (fl.typeCode === "DIVLINE") {
		return (
			<Grid item xs={12} sx={{ borderBottom: "1px solid #8f8f8f", paddingLeft: 1, paddingTop: 1, paddingBottom: 1, marginBottom: 2 }}>
				<Typography variant="h6" sx={{ fontSize: "1.1rem" }}>
					{fl.caption}
				</Typography>
			</Grid>
		);
	}

	const extraProps = { required: fl.isRequired } as any;
	if (modified) extraProps["focused"] = true;

	const gridProps = {
		xs: 12,
		sm: 6,
		md: 4,
		lg: 3,
	};

	if (fl.typeCode === "FK") {
		if (fl.isModifiable && !disabled)
			extraProps["InputProps"] = {
				endAdornment: (
					<InputAdornment position="start" disablePointerEvents={!fl.isModifiable || disabled}>
						<SearchIcon
							sx={{ cursor: "pointer" }}
							onClick={() => {
								launchLutModal();
							}}
						/>
					</InputAdornment>
				),
				disabled: true,
			};
	} else if (["RSF", "M", "REMARKS", "SF", "ESF", "TESTS"].includes(fl.typeCode)) {
		extraProps["multiline"] = true;
		gridProps.lg = 12;
		gridProps.md = 12;
		gridProps.sm = 12;
	}

	let placeHolder = "";
	switch (fl.typeCode) {
		case "TD":
			placeHolder = "HH:MM";
			break;
		case "D":
			placeHolder = "MM/DD/YYYY";
			break;
		case "DT":
			placeHolder = "MM/DD/YYYY HH:MM AM";
			break;
	}

	let inputValue = val ?? "";
	if (fl.typeCode === "FK" && fl.lutValue?.value) inputValue = (fl.lutValue?.prompt ? fl.lutValue.prompt + " - " : "") + fl.lutValue?.description;

	inputValue = isNullOrUndefined(formattedVal) ? inputValue : formattedVal;

	fl.forceSetValue = setVal;

	const captionComponent = (
		<>
			<Typography color={modified ? "primary" : ""} variant="subtitle1" display={fl.typeCode !== "SF" ? "block" : ""}>
				{fl.caption + (fl.isRequired ? "*" : "")}
			</Typography>
			{fl.isSubForm() && !isDrivingFormLine && (
				<WrappedButton
					size="small"
					caption="Add Row"
					sx={{ float: "right" }}
					onClick={() => {
						setShowSubformModal(true);
						setFormModalObj(fl.emptyForm);
					}}></WrappedButton>
			)}
		</>
	);
	//#endregion

	switch (fl.typeCode) {
		case "SF":
		case "ESF":
			let sfGridLines = fl.emptyForm.FormLines.filter((x: any) => x.isVisible && x.isSubformVisible && !["ESF", "SF", "RSF", "TESTS", "DIVLINE"].includes(x.typeCode) && !x.isGroupHeader);

			return (
				<>
					<FormModal open={showSubformModal} onClose={handleSubFormClose} form={formModalObj} isSubForm={true}></FormModal>
					<Grid item {...gridProps} sx={{ marginBottom: 2, paddingLeft: 1, paddingRight: 1 }}>
						{captionComponent}
						<TableContainer component={Paper}>
							<Table size="small">
								<TableHead>
									<TableRow>
										{isDrivingFormLine && <TableCell></TableCell>}
										{sfGridLines.map((x: any, idx: number) => {
											return <TableCell key={idx}>{x.caption}</TableCell>;
										})}
									</TableRow>
								</TableHead>
								<TableBody>
									{fl.sfValue.map((x: any, idx: number) => {
										return (
											<TableRow
												hover
												key={idx}
												sx={{ cursor: "pointer", border: dflChecked[idx] ? "4px solid #78dfff" : "initial" }}
												onClick={() => {
													if (dflChecked[idx] || !isDrivingFormLine) {
														setShowSubformModal(true);
														setFormModalObj(x);
													}
												}}>
												{isDrivingFormLine && (
													<TableCell key={-1 * (idx + 1)} sx={{ padding: 0, verticalAlign: "top" }}>
														<Checkbox
															checked={dflChecked[idx]}
															onClick={(event) => {
																// event.preventDefault();
																event.stopPropagation();
																const c = (event.target as any).checked;
																const d = cloneObject(dflChecked);
																d[idx] = c;
																x.formMode = c ? "ADD" : "NEW";
																setDflChecked(d);

																if (c && x.FormLines.filter((y: any) => y.isVisible && y.isModifiable && y.formLinesId && y.formLinesId.toLowerCase() !== x.drivingFormLineId.toLowerCase()).length > 0) {
																	setShowSubformModal(true);
																	setFormModalObj(x);
																} else {
																	setShowSubformModal(false);
																	setFormModalObj(null);
																}

																return false;
															}}
														/>
													</TableCell>
												)}
												{sfGridLines.map((col: any, idx: number) => {
													return (
														<TableCell sx={{ verticalAlign: "top" }} key={idx}>
															{x.getFormDisplay(col.columnName)}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</>
			);
		case "RSF":
			return (
				<Grid item {...gridProps} sx={{ marginBottom: 2, paddingLeft: 1, paddingRight: 1 }}>
					{captionComponent}
					<TableContainer component={Paper}>
						<Table size="small">
							<TableHead>
								<TableRow>
									{fl.srValue?.columns
										.filter((x) => x.is_visible)
										.map((x) => {
											return <TableCell key={x.column_name}>{x.caption}</TableCell>;
										})}
								</TableRow>
							</TableHead>
							<TableBody>
								{Array.isArray(val) &&
									val.map((x) => {
										return (
											<TableRow>
												{fl.srValue?.columns
													.filter((x) => x.is_visible)
													.map((col) => {
														return <TableCell>{FormatDataByTypeCode(x[col.column_name], col.type_code)}</TableCell>;
													})}
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			);
		case "M":
			return (
				<Grid item {...gridProps} sx={{ marginBottom: 2, paddingLeft: 1, paddingRight: 1 }}>
					<MemoControl isDisabled={disabled} fl={fl} captionComponent={captionComponent} checkChange={checkChange} val={val} />
				</Grid>
			);
		case "D":
			return (
				<Grid item {...gridProps} sx={{ marginBottom: 2, paddingLeft: 1, paddingRight: 1 }}>
					<LocalizationProvider dateAdapter={AdapterDateFns} sx={{ width: 1 }}>
						<DatePicker
							{...extraProps}
							disabled={!fl.isModifiable || disabled}
							label={fl.caption + (fl.isRequired ? " *" : "")}
							value={val}
							onChange={(date: any) => {
								fl.value = FormatDataByTypeCode(date, "D");
								setFormattedVal(fl.value);
								setVal(fl.value);
								checkChange();
							}}
							renderInput={(params) => <TextField fullWidth={true} sx={{ fontSize: "50px" }} {...params} />}></DatePicker>
					</LocalizationProvider>
				</Grid>
			);
		case "DT":
			return (
				<Grid item {...gridProps} sx={{ marginBottom: 2, paddingLeft: 1, paddingRight: 1, display: "flex" }}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							{...extraProps}
							disabled={!fl.isModifiable || disabled}
							label={fl.caption + (fl.isRequired ? " *" : "")}
							value={val}
							onChange={(date: any) => {
								fl.value = FormatDataByTypeCode(date, "DT");
								setFormattedVal(fl.value);
								setVal(fl.value);
								checkChange();
							}}
							renderInput={(params) => <TextField fullWidth={true} sx={{ fontSize: "50px", flex: 1, marginRight: "4px" }} {...params} />}></DatePicker>
					</LocalizationProvider>
					<TimeControl
						updateFormVal={(newTime: any) => {
							const newVal = FormatDataByTypeCode(val, "D") + " " + newTime;
							fl.value = newVal;
							setFormattedVal(fl.value);
							setVal(fl.value);
							checkChange();
						}}
						val={val}
						disabled={!fl.isModifiable || disabled}
					/>
				</Grid>
			);
		case "L":
			return (
				<Grid item {...gridProps} sx={{ marginBottom: 2, paddingLeft: 1, paddingRight: 1 }}>
					{captionComponent}
					<Checkbox
						onChange={(x: ChangeEvent) => {
							fl.value = (x.target as HTMLInputElement).checked;
							checkChange();
						}}
						checked={fl.value || false}
						value={fl.value || false}
						disabled={disabled}
						sx={{ paddingTop: 0, paddingLeft: 0 }}></Checkbox>
				</Grid>
			);
		case "TESTS":
			return (
				<Grid item {...gridProps} sx={{ marginBottom: 2, paddingLeft: 1, paddingRight: 1 }}>
					{captionComponent}
					<AssessmentRender fl={fl}></AssessmentRender>
				</Grid>
			);
		case "ESIGN":
			return (
				<Grid item {...gridProps} sx={{ marginBottom: 2, paddingLeft: 1, paddingRight: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
					{captionComponent}
					<SignatureLine onChange={checkChange} fl={fl}></SignatureLine>
				</Grid>
			);
		case "LABEL":
			return <div dangerouslySetInnerHTML={{ __html: fl.defaultValue }}></div>;
		default:
			return (
				<>
					{fl.typeCode === "FK" && <LUTModal onClose={handleLutClose} open={showLutModal} lutValue={fl.lutValue}></LUTModal>}
					<Grid item {...gridProps} sx={{ marginBottom: 2, paddingLeft: 1, paddingRight: 1 }}>
						<TextField
							disabled={!fl.isModifiable || disabled}
							color="primary"
							{...extraProps}
							onChange={(x: ChangeEvent) => {
								fl.value = (x.target as HTMLInputElement).value;
								checkChange();
							}}
							onBlur={() => {
								switch (fl.typeCode) {
									case "TD":
										fl.value = parseTimeDurationFromUser(fl.value);
										fl.value = parseIntFromTd(fl.value);
										break;
									case "EMAIL":
										fl.value = validateEmail(fl.value);
								}
								setVal(fl.value);
								setFormattedVal(FormatDataByTypeCode(fl.value, fl.typeCode));
							}}
							placeholder={placeHolder}
							label={fl.caption}
							value={inputValue}
							sx={{ width: "100%" }}
						/>
					</Grid>
				</>
			);
	}
}
